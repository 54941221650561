<template>
    <div>
        <!--        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" loosing-text="释放刷新" pulling-text="下拉刷新"-->
        <!--                          success-text="刷新成功">-->
        <!--            &lt;!&ndash;      offset="10"-->
        <!--                    finished-text="没有更多啦"&ndash;&gt;-->
        <!--        </van-pull-refresh>-->
        <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                :immediate-check="false"
                @load="onLoad">
            <div class="head-title">
                <span class="head-title-font">我的优惠券</span>
                <span class="head-title-num">{{number}}张</span>
            </div>
            <div class="main_list" v-for="(item,index) in list" :key="index">
                <!--                    v-if="item.status === 1"  -->
                <div v-if="item.status === 1" class="main-list-container">
                    <div class="main-list-container-left">
                        <img src="../assets/img/copon.png" class="main-list-container-left-img">
                        <div class="main-list-container-left-info">
                            <span class="main-list-container-left-font">{{item.couponType == 1 || item.couponType == 3 ? '¥' : ''}}</span>
                            <span class="main-list-container-left-spacing" v-if="item.couponType !== 3">{{item.preferential}}</span>
                            <span class="main-list-container-left-spacing" v-if="item.couponType === 3">0.0</span>
                            <span class="main-list-container-left-font">{{item.couponType == 2 ? '折' : ''}}</span>
                        </div>
                        <span class="main-list-container-left-type">
                                {{item.couponType == 1 ? '现金优惠' :
                                item.couponType == 2 ?  '折扣优惠' :
                                item.couponType == 3 ? '全免优惠' : ' ' }}
                            </span>
                    </div>
                    <div class="main-list-container-center">
                        <span class="main-list-container-center-title">{{ item.couponName }}</span>
                        <span class="main-list-container-center-time">有效期至 {{item.endEffectiveTime}}</span>
                    </div>
                    <!--                        <div style="display: flex;">-->
                    <!--                            <van-radio-group @change="changeCheckbox" v-model="value">-->
                    <!--                                <van-radio :name="item" checked-color="#ee0a24"></van-radio>-->
                    <!--                            </van-radio-group>-->
                    <!--                        </div>-->
                    <div style="display: flex;justify-content: center;align-items: center;" @click="handleChecked(item)">
                        <van-radio-group v-model="value">
                            <van-radio :name="item" checked-color="#ee0a24">
                            </van-radio>
                        </van-radio-group>
                    </div>
                </div>
            </div>
        </van-list>
        <div class="invalid-view" @click="handleClick">
            查看无效优惠券 <span>></span>
        </div>
        <div class="placeholder-div"></div>
        <div class="container-bottom">
            <div class="container-bottom-left">
                <span class="container-bottom-left-font">
                    {{couponType == 1 ? '现金优惠' : couponType == 2 ?  '折扣优惠' : couponType == 3 ? '全免优惠' : ' ' }}
                </span>
                <span class="container-bottom-left-num" v-if="couponType !== 3">{{preferential}}</span>
                <span class="container-bottom-left-num" v-if="couponType === 3">0</span>
                <span class="container-bottom-left-font">{{couponType == 1 || couponType == 3 ? '元' : couponType == 2 ?  '折' : ''}}</span>
            </div>
            <div class="divider"></div>
            <div @click="getInfo" class="container-bottom-right">
                <span class="container-bottom-right-font">确定</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {Button, Icon, List, Popup, PullRefresh, Radio, RadioGroup} from 'vant';
    import {couponList} from "../api/interface";
    import bus from "../directives/bus";

    export default {
        name: "coupon",
        components: {
            [Icon.name]: Icon,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
        },
        data() {
            return {
                list: [],
                finished: false,
                error: false,
                loading: false,
                total: 0,
                finishedText: "没有更多了",
                loadingText: "加载中",
                current: 1,
                size: 10,
                value: [],
                couponType: "",
                preferential: "",
                couponName: "",
                id: "",
                number: "",
            }
        },
        created() {
            this.getCouponList();
            // console.log(this.$route.query.carNumber)
        },
        mounted() {
        },
        methods: {
            getCouponList() {
                let param = {
                    current: this.current,
                    size: this.size,
                    carNumber: this.$route.query.carNumber,
                    type: this.$route.query.type
                }
                couponList(param).then((res) => {
                    // 这里将拿到的数组合并到老数组，不能直接赋值，必须是合并
                    this.list = this.list.concat(res.data.data.records);
                    // this.list = res.data.data.records;
                    // 赋值当前页、总页数、总数量
                    this.current = res.data.data.current;
                    this.total = res.data.data.total;
                    this.size = res.data.data.size;

                    // 取消loading效果
                    this.loading = false;

                    // 如果当前数组的长度超过总信息条数，不再触发滚动
                    if (this.list.length >= this.total) {
                        // 加载结束，不再触发滚动加载
                        this.finished = true;
                    }
                    let arr = [];
                    this.list.forEach((item) => {
                        if (item.status == 1) {
                            arr.push(item)
                        }
                    })
                    this.number = arr.length;
                    if (arr.length > 0) {
                        this.value = arr[0];
                    } else {
                        this.value = [];
                    }
                    this.couponType = this.value.couponType
                    this.preferential = this.value.preferential;
                    this.couponName = this.value.couponName;
                    this.id = this.value.id;
                })
            },
            handleChecked(e) {
                this.couponType = e.couponType
                this.preferential = e.preferential;
                this.couponName = e.couponName;
                this.id = e.id;
            },
            getInfo() {
                bus.$emit('conponInfo', this.id, this.name, this.couponType, this.preferential);
                this.$router.go(-1)
            },
            onLoad() {
                this.current++;
                this.getCouponList();
            },
            onRefresh() {
                this.getCouponList();
            },
            handleClick() {
                this.$router.push({
                    path: '/invalidCoupon', query: {
                        current: 1,
                        size: 10,
                        carNumber: this.$route.query.carNumber,
                        type: this.$route.query.type
                    }
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#fff;')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .head-title {
        display: flex;
        justify-content: left;
        margin: 10px 10px 0 10px;
        color: #222222;
        font-weight: 500;
    }

    .head-title-font {
        font-size: 17px;
    }

    .head-title-num {
        font-size: 13px;
        padding-top: 4px;
        padding-left: 5px
    }

    .main_list {
        margin: 10px;
        background: #F9F9F9;
        border-radius: 10px;
    }

    .main-list-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px;
    }

    .main-list-container-left {
        display: flex;
        flex-direction: column;
    }

    .main-list-container-left-img {
        width: 90px;
    }

    .main-list-container-left-info {
        font-size: 22px;
        color: #FFFFFF;
        position: absolute;
        padding: 18px 21px;
        font-weight: bold;
    }

    .main-list-container-left-font {
        font-size: 15px;
        font-weight: 400;
    }

    .main-list-container-left-spacing {
        padding: 0 3px;
    }

    .main-list-container-left-type {
        font-size: 13px;
        color: #FFFFFF;
        position: absolute;
        padding: 65px 19px 0 19px;
    }

    .main-list-container-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 0 10px;
    }

    .main-list-container-center-title {
        display: flex;
        font-size: 17px;
        color: #222222;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .main-list-container-center-time {
        display: flex;
        font-size: 13px;
        color: #888888;
        font-weight: 400;
    }

    .invalid-view {
        font-size: 15px;
        color: #888888;
    }

    .invalid-view span {
        font-weight: bold;
        font-size: 17px;
    }

    .placeholder-div {
        width: 100%;
        height: 100px;
    }

    .container-bottom {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: #F9F9F9;
    }

    .container-bottom-left {
        width: 70%;
        line-height: 40px;
        background: #1F2430;
        position: relative;
        bottom: 15px;
        left: 18px;
        border-bottom-left-radius: 27px;
        border-top-left-radius: 27px;
        text-align: left;
        padding-left: 26px;
    }

    .container-bottom-left-font {
        font-size: 13px;
        font-weight: 400;
        color: #d3d3d3;
    }

    .container-bottom-left-num {
        font-size: 22px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 8px;
    }

    .container-bottom-right {
        background: #FE4D13;
        border-bottom-right-radius: 27px;
        border-top-right-radius: 27px;
        width: 30%;
        line-height: 40px;
        position: relative;
        bottom: 15px;
        right: 20px;
    }

    .container-bottom-right-font {
        font-size: 17px;
        font-weight: 500;
        color: #FFFFFF;
        padding-right: 20px;
    }

    .divider {
        display: inline-block;
        width: 30px;
        background: #FE4D13;
        bottom: 0.4rem;
        right: 3px;
        position: relative;
        -webkit-transform: skew(-18deg);
    }
</style>
